import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import Routes from './Routes';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';

      
function App() {

  let theme = createTheme({
    palette: {
      primary: {
        main: '#09628e'
      },
      secondary: {
        main: '#343434',
      }
    },
  });
  return (
   
  <ThemeProvider theme={theme}>
        <Router slashType='slash'>
          <Routes />
        </Router>
      </ThemeProvider>

  

  );
}
export default App;
