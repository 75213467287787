import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Box,Card , Button} from '@mui/material';
import { methodPost, get_inbound } from '../../Api/Api';
import Filter from '../../components/Filter/Filter';
import { FilterBound } from '../../components/Filter/FilterData';
import axios from 'axios';
import { ExportCSV } from '../../components/ExportCSV';
import InBoundDialog from './InBoundDialog';

export default function StickyHeadTable() {
      const [page, setPage] = React.useState(0);
      const [rowsPerPage, setRowsPerPage] = React.useState(5);
      const [callSearch , setCallSearch] = useState('')
      const [data, setData] = useState([]);
      const [companyNameSearch , setCompanyNameSearch] = useState('');
      const [emailsSearch , setemailSearch] = useState('');
      const [contactPersonSearch , setcontactPersonSearch] = useState('')
      const [industrySearch , setIndustrySearch] = useState('');
      const [nameSearch , setNameSearch] = useState('');
      const [openDialogData , setOpenDialogData ] = useState(false);
      const [openDialog , setOpenDialog ] = useState(false);
      const [open, setOpen] = useState(false);
      const [status, setStatus] = useState(false);
      const [color, setColor] = useState(false);
      const [message, setMessage] = useState("");
      
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const getInbound = () =>{
      const sendData = new FormData()
      sendData.append('inboundId', "")
      axios({
        method: methodPost,
        url: get_inbound,
        cache: 'no-store', 
        data:sendData
      }).then(res => {
        if (!res.data.error) {
          setData(res.data.data);
        } else {
          setData([]);
         
        }
      }).catch(err => {
        console.log(err)
      });
    }

    useEffect(() => {
      getInbound();
  }, [] );

    const keys = Object.keys(data[0] || {}).filter((key) => key !== "inboundId");

    const OpenAdditional = (i) =>{
      setOpenDialog(true)
      setOpenDialogData(i)
    }

  return (
      <Box p={3}>
        <InBoundDialog  i={openDialogData} fetchTable={getInbound} setOpen={setOpen} setStatus={setStatus} setColor={setColor} setMessage={setMessage} openDialog={openDialog} setOpenDialog={setOpenDialog} />
      <Box>
        <Box display='flex' justifyContent='space-between' py={1}>
        <h2 className='cg'>View <span>Inbound</span></h2>
        <ExportCSV  fileName={'InBound'} csvData={data} />
        </Box>

      <Card sx={{boxShadow:3}}>

      <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
              <TableRow>
              {keys.map((key) => (
             <TableCell align='center' sx={{ fontWeight: 600 , textAlign:'center' }} key={key}>
                <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                 {key.split(/(?=[A-Z])/).join(' ')}
                </Box>
                  {key === "personType" && (
                    <Box>
                      <Filter search={contactPersonSearch} setSearch={setcontactPersonSearch} setPage={setPage} />
                    </Box>
                  )
                  }
                   {key === "name" && (
                <Box>
                <Filter search={nameSearch} setSearch={setNameSearch} setPage={setPage} />
                </Box>
                )
                }
                  {key === "email" && (
                    <Box>
                      <Filter search={emailsSearch} setSearch={setemailSearch} setPage={setPage} />
                    </Box>
                  )
                  }
                  {key === "companyName" && (
              <Box>
              <Filter search={companyNameSearch} setSearch={setCompanyNameSearch} setPage={setPage} />
              </Box>
              )
              }
                 {key === "callType" && (
                <Box>
                <Filter search={callSearch} setSearch={setCallSearch} setPage={setPage} />
                </Box>
                )
                }
                 {key === "industry" && (
                <Box>
                <Filter search={industrySearch} setSearch={setIndustrySearch} setPage={setPage} />
                </Box>
                )
                }
            </Box>
             </TableCell>
            ))}
                 <TableCell sx={{ fontWeight: 600 , textAlign:'center' , textTransform:'uppercase'  }}>Update</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
              {FilterBound(data, contactPersonSearch, emailsSearch, companyNameSearch , callSearch , industrySearch , nameSearch)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
              <TableRow key={index} sx={{bgcolor : index % 2 === 0 ? '#c3d4c8' : 'white'}}>
                {keys.map((key) => (
                  <TableCell align="center" key={key}>
                    <Box width={(key === 'remarks' || key === 'followUpComments') ? '40ch' :'20ch'}>
                    {row[key] ? row[key] : "-" }
                    </Box>
                  </TableCell>
                ))}
                   <TableCell>
              <Button variant='contained' color='success' onClick={()=>OpenAdditional(row)}>Update</Button>
              </TableCell>
              </TableRow>
            ))}
              </TableBody>



            </Table>
          </TableContainer>
          
          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={FilterBound(data,contactPersonSearch, emailsSearch, companyNameSearch , nameSearch, callSearch, industrySearch).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage} />
              
  
      </Card>
      </Box>
      </Box>
      
    );
}