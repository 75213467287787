import React, { useState } from 'react'
import { Outlet } from "react-router-dom"
import { Box } from '@mui/material';
import SideBar from './components/SideBar'
import DashbrdHeader from './components/DashbrdHeader';
// import SnackBar from './Comps/SnackBar';


function Layout() {
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    
    <Box component='div'>
      <Box sx={{ display: 'flex', minHeight: '100%', overFlow: 'hidden' }}>
        <Box>
        <SideBar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        </Box>
        <Box sx={{ flexGrow: 1, minWidth: '' }}>
          <Box position='sticky' top={0} zIndex={10}>
          <DashbrdHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}  />
          </Box>
          <Box>
          <Outlet />
          </Box>
        </Box>
        </Box>
    </Box>
  )
}

export default Layout