import React from 'react'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';

export const ExportCSV = ({csvData, fileName}) => {

    const modifiedData = csvData.map((item) => {
        const modifiedItem = {};
        for (const key in item) {
          if (item.hasOwnProperty(key)) {
            const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
            modifiedItem[capitalizedKey] = item[key];
          }
        }
        return modifiedItem;
      });


    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (modifiedData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(modifiedData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button variant="contained" 
        onClick={(e) => exportToCSV(modifiedData ,fileName)}
        startIcon={<ExitToAppIcon />}
        >
        Export
        </Button>
    )
}