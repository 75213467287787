import { v1 as uuidv1 } from 'uuid';

const viewinbound = [
   
    {
        id: uuidv1(),
        name: 'Name',
    },
   
    {
        id: uuidv1(),
        name:'Email',
     
    },
    {
        id: uuidv1(),
        name: 'Contact No',
     
    },
    {
        id: uuidv1(),
        name: 'Contacted Time',
     
    },
    {
        id: uuidv1(),
        name: 'Remarks',
     
    },
    {
        id: uuidv1(),
        name: 'Further Follow Up'
    },
   
]



const OutboundFilesampleReport  = (data) => {
    const execlData = [];
     data.map((excel) => {
        execlData.push({
            "Category Name":"",
            "Company Name":"",
            "Contact Person Name":"",
            "Position":"",
            "Industry":"",
            "Email Address":"",
            "Phone":"",
            "Website URL":"",
            "Address":"",
            "City":"",
            "Province":"",
            "Interests":"",
            "Remarks":""
     })
    })
    return execlData
}


export { viewinbound, OutboundFilesampleReport }