import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Autocomplete, Button, Card, TextField } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SnackBar from '../../components/SnackBar';
import axios from 'axios';
import {excel_outbound, get_crm_call_type, get_crm_person_type, get_excel_outbound, methodPost, update_outbound } from '../../Api/Api';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Filter from '../../components/Filter/Filter';
import { FilterBound, FilterOutBound } from '../../components/Filter/FilterData';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer} from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from 'dayjs';
import { OutboundFilesampleReport } from '../../Variables/Variables';
import { ExportSampleBomReport } from './DummyReport';
import OutBoundDialog from './OutBoundDialog';

export default function ImportOutbound() {
  
  const [OutBoundfile, setOutBoundfile] = useState(null);
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const [color, setColor] = useState(false);
  const [message, setMessage] = useState("");
  const [buttonName , setButtonName] = useState('Upload file')

  const [companyNameSearch , setCompanyNameSearch] = useState('');
  const [nameSearch , setNameSearch] = useState('');
  const [categorySearch , setCategorySearch] = useState('')
  const [websiteURL, setwebsiteURL] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showTable , setShowTable] = useState(false);
  const [buttonColor, setButtonColor] = useState('primary');
  const [input  , setInput] = useState(false);
  const[value, setValue]=useState('')
  const tomorrow = dayjs().add(1, 'day');
  const [comments, setComments] = useState('');
  const [personType , setPersonType] = useState(null);
  const [callType , setCallType] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialogData , setOpenDialogData ] = useState(false);
  const [openDialog , setOpenDialog ] = useState(false);

  const [showRow, setShowRow]=useState(false);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const submit = (e) => {
    const file = e.target.files[0];
    setOutBoundfile(file);
    if (file === null || file === undefined) {
      setMessage('File is required');
      setOpen(true);
      setStatus(false);
      setColor(false);
    } else {
      const sendData = new FormData();
      sendData.append('outbound_file', file);
      axios({
        method: 'POST',
        url: excel_outbound,
        data: sendData,
      })
        .then((res) => {
          if (res.data.error) {
            setMessage('Please Try Again');
            setOpen(true);
            setStatus(false);
            setColor(false);
            setButtonName('Upload file')
          } else {
            setOpen(true);
            setMessage('Excel file uploaded successfully');
            setStatus(true);
            setColor(true);  
            setOutBoundfile(null)
            setButtonName('File Uploaded')
            fetchTable();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };


 const fetchTable = () =>{
    const sendData = new FormData()
    sendData.append('outboundId', '');
      axios({
        method: 'POST',
        url: get_excel_outbound,
        data:sendData
      }).then(res => {
        if (!res.data.error) {
          setData(res.data.data);
          setShowTable(true)
          setCallType(null)
          setPersonType(null)
          setButtonColor('primary')
          setShowRow(false)
          setSelectedRow(null)
          setInput(true);
          setComments('');
        } else {
          setData([]);
          setShowTable(false)
          setMessage('Something Went Wrong');
          setOpen(true);
          setStatus(false);
          setColor(false);
        }
      }).catch(err => {
        console.log(err)
      });
  }

  useEffect(()=>{
  fetchTable();
  },[])

  const keys = Object.keys(data[0] || {}).filter((key) => key !== "outboundId");

  const onSubmit = (i) => {
    setOpenDialog(true)
    setOpenDialogData(i);
   }

  const samplereport  = [            
  "Category Name",
  "Company Name",
  "Contact Person Name",
  "Position",
  "Industry",
  "Email Address",
  "Phone",
  "Website URL",
  "Address",
  "Province",
  "Interests",
  "Remarks"]
    
  const csvdata = OutboundFilesampleReport(samplereport);


  const keysToKeep = [
    "categoryName",
    "name",
    "companyName",
    "industry",
  ];
  
  const filteredKeys = keys.filter((key) => keysToKeep.includes(key));



  return (
    <Box sx={{ p: 3 }}>
    <SnackBar open={open} message={message} setOpen={setOpen} status={status} color={color} />

    <OutBoundDialog i={openDialogData} fetchTable={fetchTable} setOpen={setOpen} setStatus={setStatus} setColor={setColor} setMessage={setMessage} openDialog={openDialog} setOpenDialog={setOpenDialog} />

    <Grid container spacing={4}>

    <Grid item xs={12} display='flex' flexDirection='row' justifyContent='space-between' gap={3} py={4}>
    <Box sx={{ textAlign: 'center' }}>
    <label htmlFor="bomfile">
    <input onChange={submit} className='d-none' id="bomfile" type="file" />
    <Button
    startIcon={<InsertDriveFileIcon />}
    variant="contained"
    color={OutBoundfile !== null ? 'secondary' : 'primary'}
    component="span">
      {buttonName}
    </Button>
    </label>
    </Box>
    <Box>
    <ExportSampleBomReport csvData={csvdata} fileName='OutBoud_Sample_Report' />
    </Box>
    </Grid>
     

    <Grid item xs={12} py={4}>
    <Box p={2}> 
    <Box>
      <h4 className='cg`'>Imported <span>Outbound</span></h4>
      <Card sx={{boxShadow:3}} mt={1}>
      <TableContainer>
            <Table stickyHeader aria-label="sticky table" size='small'>
              <TableHead sx={{ whiteSpace: 'nowrap', bgcolor: 'primary.main' }}>
              <TableRow>
              {filteredKeys.map((key) => (
             <TableCell 
             sx={{
               fontWeight: 600 ,
                textAlign:'center' 
                 }} 
                 key={key}>
                <Box sx={{ display: 'flex' , justifyContent:'center'}}>
                <Box sx={{ my: 'auto', textTransform: 'uppercase' }}>
                {key.split(/(?=[A-Z])/).join(' ')}
                </Box>
                  {key === "categoryName" && (
                    <Box>
                      <Filter search={categorySearch} setSearch={setCategorySearch} setPage={setPage} />
                    </Box>
                  )
                  }
                  {key === "name" && (
                    <Box>
                      <Filter search={nameSearch} setSearch={setNameSearch} setPage={setPage} />
                    </Box>
                  )
                  }
                  {key === "companyName" && (
              <Box>
              <Filter search={companyNameSearch} setSearch={setCompanyNameSearch} setPage={setPage} />
              </Box>
              )
              }
            </Box>
             </TableCell>
            ))}
             <TableCell sx={{ fontWeight: 600 , textAlign:'center'  }} >FOLLOW</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
              {FilterOutBound(data, categorySearch, nameSearch, companyNameSearch)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
              <TableRow key={index} sx={{bgcolor : index % 2 === 0 ? '#c3d4c8' : 'white'}}>
                {filteredKeys.map((key) => (
                  <TableCell align="center" key={key}>
                    <Box>
                    {row[key]}
                    </Box>
                  </TableCell>
                ))}
      
                <TableCell>
                <Button 
                type="submit"
                fullWidth
                onClick={() => onSubmit(row)}
                variant="contained"
                color='warning'
                >
                 Follow
                </Button>
                </TableCell>
              </TableRow>
            ))}
              </TableBody>



            </Table>
          </TableContainer>

          <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={FilterOutBound(data, categorySearch, nameSearch, companyNameSearch).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage} />
      </Card>
      </Box>
    </Box>
    </Grid>

  </Grid>

    </Box>
  )
}


