import { Box } from '@mui/material'
import React from 'react'

export default function WelcomePage() {
  return (
    <div>
      <Box p={3}>
        <h1>
        Welcome
        </h1>
      </Box>
    </div>
  )
}
