const FilterData = (data, search, searchfeild) => {
  const { searchFeildOne, searchFeildTwo } = searchfeild;
  if (search === '') {
    return data
  } else if (searchFeildOne.toString().toLowerCase().includes(search.toLowerCase()) || searchFeildTwo.toString().toLowerCase().includes(search.toLowerCase())) {
    return data
  }
}


const FilterBound = (data, contactPersonSearch, emailsSearch, companyNameSearch , callSearch , industrySearch , nameSearch) => {
  if (!companyNameSearch && !contactPersonSearch && !emailsSearch && !callSearch && !industrySearch && !nameSearch) {
    return data;
  }

  return data.filter((row) => {
    if (companyNameSearch && row.companyName.toString().toLowerCase().includes(companyNameSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (contactPersonSearch && row.personType.toString().toLowerCase().includes(contactPersonSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    if (emailsSearch && row.email.toString().toLowerCase().includes(emailsSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    if (callSearch && row.callType.toString().toLowerCase().includes(callSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }
    if (industrySearch && row.industry.toString().toLowerCase().includes(industrySearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    if (nameSearch && row.name.toString().toLowerCase().includes(nameSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }
    return false; // Exclude if it doesn't match either search.
  });
};


const FilterOutBound = (data, categorySearch, nameSearch, companyNameSearch , FollowSearch , callSearch , industrySearch) => {
  if (!companyNameSearch && !categorySearch && !nameSearch && !FollowSearch && !callSearch && !industrySearch) {
    return data;
  }

  return data.filter((row) => {
    if (companyNameSearch && row.companyName.toString().toLowerCase().includes(companyNameSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the employeeId search.
    }

    if (categorySearch && row.categoryName.toString().toLowerCase().includes(categorySearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    if (nameSearch && row.name.toString().toLowerCase().includes(nameSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    if (FollowSearch && row.followUpTime.toString().toLowerCase().includes(FollowSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    if (callSearch && row.callType.toString().toLowerCase().includes(callSearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }

    if (industrySearch && row.industry.toString().toLowerCase().includes(industrySearch.toLowerCase())) {
      return true; // Include in the filter if it matches the projectCode search.
    }


    return false; // Exclude if it doesn't match either search.
  });
};



export { FilterData, FilterBound , FilterOutBound};