import React, { useState } from 'react';
import logo from '.././images/SHOPZTREND_NEW.png';
import {
  Drawer,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Collapse
} from '@mui/material';

import { Link } from 'react-router-dom'
import ResponsiveBd from '../ResponsiveBd';
// import ResponsiveBd from './ResponsiveBd';
import CategoryIcon from '@mui/icons-material/Category';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

function SideBar({ setMobileOpen, mobileOpen }) {



  const responsive = ResponsiveBd();
  const drawerWidth = 280;
    const [openOne, setOpenOne] = useState(false);
    const [openTwo, setOpenTwo] = useState(false);
    const [openThree, setOpenThree] = useState(false);
    const [openFour, setOpenFour] = useState(false);
    const [openFive, setOpenFive] = useState(false);
    const [openSix, setOpenSix] = useState(false);
    const [openSeven, setOpenSeven] = useState(false);
    const [openEight, setOpenEight] = useState(false);
    const [openNine, setOpenNine] = useState(false);
    const [openTen, setOpenTen] = useState(false);
   const [openOrder, setOpenOrder] = useState(false);

   const access = JSON.parse(localStorage.getItem('access'));

   const {outboundView ,inboundView } = access ;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
};

const handleClickOne = () => {
    setOpenOne(!openOne);
  };

  
const handleClickTwo = () => {
    setOpenTwo(!openTwo);
  };

  const handleClickThree = () => {
    setOpenThree(!openThree);
  };

  const handleClickFour = () => {
    setOpenFour(!openFour);
  };

  const handleClickFive = () => {
    setOpenFive(!openFive);
  };
  const handleClickSix = () => {
    setOpenSix(!openSix);
  };
  const handleClickSeven = () => {
    setOpenSeven(!openSeven);
  };
  const handleClickEight = () => {
    setOpenEight(!openEight);
  };
  const handleClickNine = () => {
    setOpenNine(!openNine);
  };
const handleClickTen = () => {
setOpenTen(!openTen);
};

const handleClickStoreOrders = () =>{
setOpenOrder(!openOrder);
}


  const drawer = ( 
    
      <Box sx={{ height: '100%' }}>
      {/* Logo */}
      <Box sx={{ px: 2 }}>
      <Box sx={{ textAlign: 'center' }}>
     <img className='dashlogo' src={logo} alt="logo" 
      style={{ objectFit: 'contain', height: '115px', width: '235px', mixBlendMode: 'multiply', verticalAlign:'top'}} loading='lazy' />
      </Box>
      </Box>


      {inboundView=== 'true' && (<>
      <ListItem onClick={handleClickOne} button sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main' , cursor:'auto' , ':hover':{bgcolor:'#EAF4FC'} }} >
        <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><CallReceivedIcon sx={{ color: '#faa634' }} /></ListItemIcon>
          <ListItemText >
            <Typography component={'div'} sx={{ display: 'flex', fontWeight: 600, justifyContent: 'space-between' }} >
              <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> InBound</Box>
            </Typography>
          </ListItemText>
        </ListItem>
        <Collapse in timeout="auto" unmountOnExit>
          <List sx={{ px: 2 }}>
          <Link to="createinbound" className=" text-nowrap text-decoration-none fs-5" style={{textDecoration:'none'}}>
              <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <AddBoxIcon color='success' />
                </ListItemIcon>
                <ListItemText sx={{ color: 'primary.main' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                    Create Inbound
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
          <Link to="viewinbound" className=" text-nowrap text-decoration-none fs-5 " style={{textDecoration:'none'}}>
              <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
                <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <VisibilityIcon color='success' />
                </ListItemIcon>
                <ListItemText sx={{ color: 'primary.main' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                   View Inbound
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
          </List>
        </Collapse>
        </>)}


        {inboundView=== 'true' && (<>
        <ListItem onClick={handleClickOne} button sx={{ textAlign: 'left', borderRadius: 2, py: 2, color: 'primary.main', cursor:'auto' , ':hover':{bgcolor:'#EAF4FC'} }} >
        <ListItemIcon sx={{ px: 2, color: 'primary.main' }}><ArrowOutwardIcon sx={{ color: '#faa634' }} /></ListItemIcon>
          <ListItemText >
            <Typography component={'div'} sx={{ display: 'flex', fontWeight: 600, justifyContent: 'space-between' }} >
              <Box component={'div'} sx={{ whiteSpace: 'nowrap' }}> OutBound</Box>
            </Typography>
          </ListItemText>
        </ListItem>
        <Collapse in timeout="auto" unmountOnExit>
          <List sx={{ px: 2 }}>
          <Link to="createoutbound" className=" text-nowrap text-decoration-none fs-5 " style={{textDecoration:'none'}}>
              <ListItem onClick={handleDrawerToggle} button sx={{ borderRadius: 2, color: 'primary.main' }}>
                <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <AddBoxIcon color='success' />
                </ListItemIcon>
                <ListItemText sx={{ color: 'primary.main' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                    Create Outbound
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
          <Link to="viewoutbound" className="text-nowrap text-decoration-none fs-5" style={{textDecoration:'none'}}>
              <ListItem button onClick={handleDrawerToggle} sx={{ borderRadius: 2, color: 'primary.main' }}>
                <ListItemIcon sx={{ px: 2, color: 'primary.main' }}>
                <VisibilityIcon color='success' />
                </ListItemIcon>
                <ListItemText sx={{ color: 'primary.main' }} >
                  <Typography sx={{ fontWeight: 500 }}>
                   View Outbound
                  </Typography>
                </ListItemText>
              </ListItem>
            </Link>
          </List>
        </Collapse>
       </>)}
     
      <Box p={1}>

      <a href="https://mdqualityapps.com" target="_blank" rel="noopener" className=" text-nowrap text-decoration-none text-center fs-5 fixbot" style={{textDecoration:'none'}}>
      <Typography sx={{ fontWeight: 600, fontSize:15 , color:'#faa634' }}>MDQuality Apps Solutions</Typography>
      </a>

      </Box>

  
  </Box>)
  return (
    <Box>
      <Drawer
        open={mobileOpen}
        variant={responsive ? 'temporary' : 'permanent'}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: 'block',
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            bgcolor: '#EAF4FC',
            color: '#4B4B4B'

          },
        }}
        anchor="left"
      >
        {drawer}
      </Drawer>
    </Box>
  )
}

export default SideBar;