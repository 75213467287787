import { useState } from "react";
import { Box, Grid, IconButton, InputAdornment, Stack , Typography, Container, TextField, Button } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import axios from "axios";
import { login, methodPost } from "../../Api/Api";
import { startUrl } from "../../Routes";
import {AES} from "crypto-js"
import SnackBar from "../../components/SnackBar";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';

export default function Login({ setsuccessOpen, setsuccessMessage, setsuccessStatus, setsuccessColor }) {
   const [showPassword, setShowPassword] = useState(true);
   const navigate = useNavigate();
   const [open, setOpen] = useState(false);
   const [status, setStatus] = useState(false);
   const [color, setColor] = useState(false);
   const [message, setMessage] = useState("");
   const[email, setEmail] = useState('');
   const[password, setPassword]= useState('');
   const [errMsg , setErrMsg] = useState('');


   function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <a href="https://www.shopztrend.com/" style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">
        Shopztrend
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    );
  }

   const handleSubmit = (e) => {
    e.preventDefault();
    if(email !== "" && password !== ""){
      const serverData = new FormData()
      serverData.append('email', email);
      serverData.append('password',password);
        if (!navigator.onLine) {
          setMessage('Your internet is in Offline')
          setOpen(true)
          setStatus(false)
          setColor(false)
        } else {
          axios({
            method: methodPost,
            url: login,
            data: serverData,
          }).then(res => {
            if (res.data.error) {
              setMessage(res.data.message)
              setOpen(true)
              setStatus(false)
              setColor(false)
            } else {
              localStorage.setItem('AdminBoundauth', true);
              localStorage.setItem('AdminOutUserId', res.data.data.adminId);
              localStorage.setItem('access', JSON.stringify({
              inboundView:res.data.data.inboundView,
              outboundView:res.data.data.outboundView,
              }))
              navigate(`${startUrl}app`)
              setsuccessOpen(true)
              setsuccessMessage(res.data.message)
              setsuccessStatus(true)
              setsuccessColor(true)
    
            }
          }).catch(err => {
            alert('Oops something went wrong' + err)
          });
        }
      }
      }
  
  return (
    <Box className='LoginPage'>
    <Container component="main" maxWidth="xs">
    <SnackBar open={open} setOpen={setOpen} status={status} color={color} message={message} />
    <Box
    sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color:'#2d2d2d'
    }}
    >
    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
    <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
    Sign in
    </Typography>
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }} padding={4}>
    <Stack textAlign='left' spacing={2}>
    <Box>
    <Typography>Email</Typography>
    <TextField
    margin="normal"
    required
    fullWidth
    id="email"
    name="email"
    type='email'
    autoComplete="email"
    onChange={(e)=>setEmail(e.target.value)}
    />
    </Box>
    <Box>
    <Typography>Password</Typography>
    <TextField
    margin="normal"
    required
    fullWidth
    name="password"
    type="password"
    id="password"
    autoComplete="current-password"
    onChange={(e)=>setPassword(e.target.value)}
    helperText={errMsg}
    />
    </Box>
    </Stack>
    <Button
    type="submit"
    fullWidth
    variant="contained"
    sx={{ mt: 3, mb: 2, bgcolor:'#faa634', ':hover':{bgcolor:'#faa634'} }}
    >
    Sign In
    </Button>

    {/* <Grid container>
    <Grid item xs>
    <Link variant="body2" style={{color:'#262626'}} to='resetpassword'>
    Forgot password?
    </Link>
    </Grid>
    </Grid> */}

    </Box>
    </Box>
    <Copyright sx={{ mt: 6 }} />
    </Container>
    </Box>
  );
}
