import React, { useState } from 'react'
import {  Navigate, useRoutes } from 'react-router-dom'
import CreateInbound from './Pages/InBound/CreateInbound';
import ViewInbound from './Pages/InBound/ViewInbound';
import ViewOutbound from './Pages/OutBound/ViewOutbound';
import Layout from './Layout';
import Login from './Pages/Login/Login';
import WelcomePage from './Pages/WelcomePage';
import OutBoundTab from './Pages/OutBound/OutBoundTab';


// this is pages

// this variable is for starting url
//this variable is for nadi local development 
// export const startUrl = `/nadipms/`;

//this variable is for local development 
export const startUrl = `/`;

// this variable for testing server
// export const startUrl = `/nadi/testing/`;

// this variable is for live server
// export const startUrl = `/nadi/web/`;

function Routes() {
   const auth = localStorage.getItem('AdminBoundauth');
   const [successOpen, setsuccessOpen] = useState(false);
   const [successMessage, setsuccessMessage] = useState('');
   const [successStatus, setsuccessStatus] = useState(false);
   const [successColor, setsuccessColor] = useState(false);


    return useRoutes([
      {
                          path: startUrl,
                          element: <Login   setsuccessOpen={setsuccessOpen} setsuccessMessage={setsuccessMessage} setsuccessStatus={setsuccessStatus} setsuccessColor={setsuccessColor} />
                    },
                    {
                          path: `${startUrl}app`,
                          element: auth ? <Layout   successOpen={successOpen} setsuccessOpen={setsuccessOpen} successMessage={successMessage} successStatus={successStatus} successColor={successColor} /> : <Navigate to={startUrl} />,
      children:[
        {
          path: `${startUrl}app`,
          element: <Navigate to ={`home`} />,
        },
    {
      path: 'createinbound',
      element: <CreateInbound/>,
    },
    {
      path: 'home',
      element: <WelcomePage />,
    },
    {
      path: 'viewinbound',
      element: <ViewInbound />,

      
},
{
  path: 'createoutbound',
  element: <OutBoundTab />,
},
{
  path: 'viewoutbound',
  element: <ViewOutbound/>,
},
{ path: `logout`, element: <Navigate to={startUrl} /> },
] 
}
    ])
}

export default Routes;